<template>
   <app-header/>
   <div class="container-fluid p-0">
            <div class="d-flex topspace-90" dir="rtl">
                <app-side-bar/>
                <app-not-founds/>
            </div>
   </div>
   <app-footer/>
</template>

<script>
import AppFooter from '../../components/layout/AppFooter.vue'
import AppHeader from '../../components/layout/AppHeader.vue'
import AppSideBar from '../../components/layout/AppSideBar.vue'
import AppNotFounds from '../../components/Pages/AppNotFounds.vue'
export default {
   name: 'NotFounds',
   components: { AppHeader, AppSideBar, AppFooter, AppNotFounds },
}
</script>

<style>

</style>